import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import i18n from '@/apps/landing/main';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		// DASHBOARD
		{ path: '/', meta: { title: 'Startseite', visible: true }, component: () => import('@/apps/landing/views/Home.vue') },
		{ path: '/contact', meta: { title: 'Kontakt', visible: true }, component: () => import('@/apps/landing/views/Contact.vue') },
		// SHOP
		{ path: '/shop', meta: { title: 'Shop', visible: true }, component: () => import('@/apps/landing/views/shop/Catalog.vue') },
		{ path: '/shop/checkout', meta: { title: 'Kasse' }, component: () => import('@/apps/landing/views/shop/Checkout.vue') },
		{ path: '/shop/product/webspaces', meta: { title: 'Webspaces' }, component: () => import('@/apps/landing/views/shop/product/Webspaces.vue') },
		{ path: '/shop/product/domains', meta: { title: 'Domains' }, component: () => import('@/apps/landing/views/shop/product/Domains.vue') },
		{ path: '/shop/product/vps', meta: { title: 'vServer' }, component: () => import('@/apps/landing/views/shop/product/VPS.vue') },
		{ path: '/shop/product/kvm', meta: { title: 'KVM Server' }, component: () => import('@/apps/landing/views/shop/product/KVM.vue') },
		{ path: '/shop/product/dedi', meta: { title: 'Dedicated Server' }, component: () => import('@/apps/landing/views/shop/product/Dedi.vue') },
		{ path: '/shop/product/licenses', meta: { title: 'Lizenzen' }, component: () => import('@/apps/landing/views/shop/product/Licenses.vue') },
		// LEGAL
		{ path: '/legal-notice', meta: { title: 'Impressum' }, component: () => import('@/apps/landing/views/Legal/LegalNotice.vue') },
		{ path: '/privacy-policy', meta: { title: 'Datenschutz' }, component: () => import('@/apps/landing/views/Legal/PrivacyPolicy.vue') },
		{ path: '/tos', meta: { title: 'AGB' }, component: () => import('@/apps/landing/views/Legal/TOS.vue') },
		// UTILS
		{ path: '/choose-lang', meta: { title: 'Language' }, component: () => import('@/apps/landing/views/utils/ChooseLang.vue') },
		{ path: '/coming-soon', meta: { title: 'Coming Soon' }, component: () => import('@/apps/landing/views/utils/ComingSoon.vue') },
		{ path: '/maintenance', meta: { title: 'Maintenance' }, component: () => import('@/apps/landing/views/utils/Maintenance.vue') },
		// 404
		{ path: '/:pathMatch(.*)*', meta: { title: 'Error 404' }, component: () => import('@/apps/landing/views/utils/PageError.vue') },
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	// Inject meta information
	const title: string = (to.meta.title as string) || 'ComingSoon';
	const description: string = (to.meta.description as string) || 'ComingSoon';
	const visible: boolean = to.meta.visible == false;

	document.title = title + ' | Key2Host';

	const descriptionElement = document.querySelector('head meta[name="description"]');
	if (descriptionElement) {
		descriptionElement.setAttribute('content', description);
	}

	let robotsElement = document.querySelector('head meta[name="robots"]');
	if (!robotsElement) {
		robotsElement = document.createElement('meta');
		robotsElement.setAttribute('name', 'robots');
		document.head.appendChild(robotsElement);
	}
	robotsElement.setAttribute('content', visible ? 'index, follow' : 'noindex, nofollow');

	// Language check and other logic...
	const langC = Cookies.get('lang');
	if (!langC && to.path !== '/choose-lang') {
		const browserLang = navigator.language;
		const supportedLangs = ['de', 'en'];
		const detectedLang = supportedLangs.find(lang => browserLang.startsWith(lang));

		if (detectedLang) {
			i18n.global.locale.value = detectedLang as 'de' | 'en';
		} else {
			next('/choose-lang');
		}
	} else {
		if (langC) {
			i18n.global.locale.value = langC as 'de' | 'en';
		}
	}

	next();
});

export default router;